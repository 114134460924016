<template>
  <div>
    <div class="search-container">
      <Input class="input-container" :label="$t('evrimed_merm_id')" :placeholderText="$t('enter_merm_id')" :isRequired="true" v-model="imei"/>
      <Button class="search-button-container" :label="$t('_search')" :color="'primary'" @click="fetchMermData()"/>
    </div>
    <p class="text-ibm-plex-sans loading" v-if="mermDetailsLoading">{{ $t('Loading') }}  <font-awesome-icon icon="spinner" class="fa-lg" spin />
    </p>
    <div v-if="mermDetailsLoaded">
      <div v-if="mermData && mermData.length==0" class="loading">
        <b class="text-ibm-plex-sans imei-null">{{ $t('merm_details_not_found') }}</b>
      </div>
      <div v-else class="merm-data-container">
        <div v-for="merm in mermData" :key="merm.key" class="merm-data">
          <p>{{ $t(merm.key) }} </p>
          <div>
            <a v-if="merm.key==='patient_id_web' && merm.value!=='-'" :href="`patient/${merm.value}`" class="merm-value-patient">{{ merm.value }}</a>
            <b v-else>{{ merm.value==null ? "-" : $t(merm.value) }}</b>
          </div>
        </div>
      </div>
      <div class="pull-right center-float" v-if="mermDetailsLoaded && isImeiStatusEditable && isMermEnhancementEnabled">
        <Button class="button-container" :label="$t('change_status')" :color="'primary'"  @click="changeStatusClick()"/>
      </div>
      <Modal class="modal-without-scroll" v-if="isModalVisible" :headingText="$t('change_status')" @close="close()" @click="onOk()">
        <div slot="body">
            <RadioGroup
              v-model="newStatus"
              :allOptions="imeiStatusValue"
              :label="''"
              :labelText="'light'"
            />
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import Input from '../../../shared/components/Input.vue'
import Button from '../../../shared/components/Button.vue'
import Modal from '../../../shared/components/Modal.vue'
import RadioGroup from '../../../shared/components/RadioGroup.vue'
import { mapActions } from 'vuex'

export default {
  name: 'Evrimed',
  data () {
    return {
      imei: null,
      mermDetailsLoading: false,
      mermDetailsLoaded: false,
      mermData: [],
      isImeiStatusEditable: false,
      isModalVisible: false,
      hasError: false,
      currentStatus: '',
      newStatus: '',
      statusUpdated: false,
      imeiStatusValue: [
        { Key: 'Not Available', Value: 'Not Available' },
        { Key: 'Available', Value: 'Available' },
        { Key: 'Lost or Damaged', Value: 'Lost/Damaged' }
      ],
      isMermEnhancementEnabled: false
    }
  },
  async mounted () {
    const response = await this.getLoggedInUserConfigValues({ configNames: ['MermEnhancementEnabled'], throwError: false })
    this.isMermEnhancementEnabled = JSON.parse(response?.MermEnhancementEnabled ?? false)
  },
  components: {
    Input,
    Button,
    Modal,
    RadioGroup
  },
  methods: {
    ...mapActions('Evrimed', ['getMermDetails', 'checkPermission', 'updateImeiStatus']),
    ...mapActions(['getLoggedInUserConfigValues']),
    async fetchMermData () {
      this.mermDetailsLoading = true
      this.mermDetailsLoaded = false
      this.isImeiStatusEditable = false
      const imeiData = await this.getMermDetails(this.imei)
      if (imeiData?.imei) {
        this.mermData = [
          { key: 'imei_serial_number', value: imeiData.imei },
          { key: 'status', value: imeiData.status },
          { key: 'last_heartbeat_received', value: imeiData.lastSeen },
          { key: 'patient_id_web', value: imeiData.entityId ?? '-' },
          { key: 'last_opened', value: imeiData.lastOpened },
          { key: 'hierarchy', value: imeiData.hierarchy },
          { key: 'last_known_battery_level', value: imeiData.lastBattery },
          { key: 'rt_hours', value: imeiData.rtHours }
        ]
        if (imeiData.status !== 'Allocated') {
          this.isImeiStatusEditable = true
          this.newStatus = this.currentStatus = imeiData.status
        }
      } else {
        this.mermData = []
      }
      this.mermDetailsLoading = false
      this.mermDetailsLoaded = true
    },
    changeStatusClick () {
      this.isModalVisible = true
    },
    close () {
      this.isModalVisible = false
      this.newStatus = this.currentStatus
    },
    async onOk () {
      this.statusUpdated = await this.updateImeiStatus({ data: { imei: this.imei, status: this.newStatus } })
      if (this.statusUpdated) {
        this.mermData.forEach(merm => {
          if (merm.key === 'status') {
            merm.value = this.newStatus
            return this.mermData
          }
        })
      }
      this.isModalVisible = false
    }
  },
  beforeMount () {
    this.checkPermission()
  }
}
</script>

<style  scoped>
.input-container {
    display: inline-flex;
    box-sizing: content-box;
    align-items: center;
    min-width: 50%;
    margin: 20px 10px 50px 20px;
}
.search-button-container {
  position: absolute;
  margin-top: 20px;
}
.search-container {
    border: 1px solid var(--input-border);
    align-items: center;
    border-radius: 6px;
    margin: 50px;
}
.loading {
    text-align: center;
    margin-top: 10px !important;
    margin-bottom: 5px !important;
    color: var(--text-primary-color)
}
.merm-data-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-radius: 4px;
    margin: 15px 50px 15px 50px;
    min-height: 220px;
    color: var(--text-primary-color);
    box-shadow: 0px 2px 2px var(--very-light-shadow);
    background-color: var(--background-color-primary-light-dark);
    padding: 10px 0 10px 0;
}
.pull-right {
  margin: 15px 50px 15px 50px;
  justify-content: flex-end;
  display: flex;
}
.modal-without-scroll >>> .modal{
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  background: var(--notes-box-color);
  border-radius: 16px;
  padding: 32px;
  max-width: 519px;
  min-width: 500px;
  overflow-y: scroll;
  overflow-y: hidden;
  max-height: 88%;
  margin-top: 8%;
  margin-bottom: 4%;
}
.merm-data {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 15px;
    margin-left: 15px;
}
.merm-value-patient {
  color: #0053FF;
}
</style>
